import React from 'react';
import { useIntl } from "gatsby-plugin-react-intl";
import NavbarTwo from "../components/App/NavbarTwo"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import ProductCategories from '../components/MachineLearningAISolutions/ProductCategories';



const Products = () => {
  const intl = useIntl();
  const canonical = intl.locale === "en" ? `${process.env.GATSBY_MAIN_URL}/products` : `${process.env.GATSBY_MAIN_URL}/fr/products` ;

    return (
        <Layout title='Product Category Page' canonical={canonical} >
        <NavbarTwo />
            <ProductCategories/>
            <Footer />
        </Layout>
    );
}

export default Products;